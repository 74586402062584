import React from "react"
import styled from "styled-components"
import { FormattedHTMLMessage, useIntl } from "gatsby-plugin-intl"

import ContactUs from "../assets/ContactUs.svg"

import Footer from "../components/Footer"
import Layout from "../components/Layout"
import Meta from "../components/Meta"
import Navbar from "../components/Navbar"
import SafeMailto from "../components/atoms/SafeMailto"
import { H1, H2 } from "../components/atoms/ResTypography"
import { PageProps } from "../helpers/props"

const SectionHero = styled.section`
  .elist {
    margin-top: 1.5rem;

    h2.title {
      margin-bottom: 0.5rem;

      font-size: 1.5rem;
    }

    div,
    p {
      display: block;
      margin-bottom: 1rem;
    }
  }

  .ilc {
    display: flex;
    justify-content: center;
  }
`

const ContactPage: React.FunctionComponent<PageProps> = ({ location }) => {
  const intl = useIntl()
  const title = intl.formatMessage({ id: "Contacts" })

  return (
    <Layout>
      <Meta pathname={location.pathname} title={title} />
      <Navbar isFixedTop={false} />
      <SectionHero className="section" id="hero">
        <div className="container">
          <div className="columns">
            <div className="column">
              <H1 className="title">{title}</H1>
              <p>
                <FormattedHTMLMessage id="h_more_ways_to" />
              </p>
            </div>
          </div>
          <div className="columns is-flex-desktop">
            <div className="column is-two-thirds-tablet is-three-fifths-desktop">
              <div className="columns">
                <div className="column is-half">
                  <div className="elist">
                    <H2 className="title">{intl.formatMessage({ id: "General inquiry" })}</H2>
                    <div>
                      <SafeMailto d="ailedxam" t="ti" u="olleh" isMailVisible={true} />
                    </div>
                    <H2 className="title">{intl.formatMessage({ id: "Customer support" })}</H2>
                    <div>
                      <SafeMailto d="ailedxam" t="ti" u="pleh" isMailVisible={true} />
                    </div>
                  </div>
                </div>
                <div className="column is-half">
                  <div className="elist"></div>
                </div>
              </div>
            </div>
            <div className="column ilc is-hidden-mobile">
              <img alt="" className="il" src={ContactUs} />
            </div>
          </div>
        </div>
      </SectionHero>
      <Footer />
    </Layout>
  )
}

export default ContactPage
