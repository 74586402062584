import React from "react"

interface SafeMailtoProps {
  children?: any
  className?: string
  d: string
  isMailVisible?: boolean
  subject?: string
  t: string
  u: string
}

const getRight = (s: string) =>
  s
    .split("")
    .reverse()
    .join("")

const getAllRight = (d: string, t: string, u: string): string => `${getRight(u)}@${getRight(d)}.${getRight(t)}`

const getWindowLocation = (d: string, t: string, u: string, subject?: string): string => {
  return !!subject ? `mailto:${getAllRight(d, t, u)}?subject=${subject}` : `mailto:${getAllRight(d, t, u)}`
}

const SafeMailto: React.FunctionComponent<SafeMailtoProps> = ({
  children,
  className,
  d,
  isMailVisible,
  subject,
  t,
  u,
}) => {
  function onClick() {
    if (typeof window !== "undefined" && typeof window.location !== "undefined") {
      window.location.href = getWindowLocation(d, t, u, subject)
    }
  }

  return (
    <a className={className} onClick={onClick}>
      {isMailVisible ? getAllRight(d, t, u) : undefined}
      {children}
    </a>
  )
}

export default SafeMailto
